import React from "react"
import { graphql, Link } from "gatsby"
import "../assets/style.scss"
import SEO from "../components/seo"
import Header from "../components/Header"
import Footer from "../components/Footer"
import join from "../images/join.webp"
import joinLow from "../images/join_low.webp"
import agami from "../images/agami_.png"
import ankur from "../images/ankur_.png"
import friends from "../images/friends_.png"
import metamorphosis from "../images/metamorphosis.png"
import ReactMarkdown from "react-markdown"

import LazyImage from "../components/LazyImage"

const PartnerData = [
  {
    id: 1,
    image: ankur,
    link: "https://ankurintl.org/",
  },
  {
    id: 2,
    image: agami,
    link: "https://www.agami.org/",
  },
  {
    id: 3,
    image: friends,
    link: "https://www.swapnanagar.org/friends.html",
  },
  {
    id: 4,
    image: metamorphosis,
    link: "http://metamorphosis.com.bd/",
  },
]

const JoinUsPage = ({ data }) => (
  <div>
    <SEO title="Join Us" />
    <Header currentPage="join-us" />
    <LazyImage
      lowRes={joinLow}
      highRes={join}
      alt="joinUsRight"
      class="joinUsRight position-absolute slideshow1right"
    ></LazyImage>

    <div className="container mt-5  pt-5">
      <div className="m-top join-title fade-in">
        <h1>{data.strapiJoinUsPage.title}</h1>

        <ReactMarkdown>{data.strapiJoinUsPage.description}</ReactMarkdown>
        <br />
        <div className="row">
          <Link
            to="/volunteer"
            className="col-lg-4 mx-3 px-0 btn btn-primary py-2 my-2"
          >
            Join&nbsp;as&nbsp;Volunteer
          </Link>
          <Link
            to="/partner"
            className="col-lg-4 mx-3 px-0 btn btn-success py-2 my-2"
          >
            Be&nbsp;a&nbsp;Partner
          </Link>
        </div>
      </div>
    </div>

    <div className="container" style={{ marginTop: "20rem" }}>
      <div className="row justify-content-stretch">
        <div className="col-lg-6 pl-0">
          <ReactMarkdown>
            {data.strapiJoinUsPage.benefits_volunteer}
          </ReactMarkdown>
        </div>

        <div className="col-lg-6 pr-0">
          <ReactMarkdown>
            {data.strapiJoinUsPage.benefits_partner}
          </ReactMarkdown>
        </div>
      </div>
    </div>

    <div className="container mt-5">
      <h2>Our Partners</h2>
      <div className="row justify-content-center align-items-end">
        {PartnerData.map(partner => (
          <a
            key={partner.id}
            href={partner.link}
            target="_blank"
            rel="noreferrer noopener"
            className="col-sm-3 my-4"
          >
            <img src={partner.image} alt={partner.id} height="140px"></img>
          </a>
        ))}
      </div>
    </div>
    <div className="m-top"></div>
    <Footer />
  </div>
)

export default JoinUsPage

export const pageQuery = graphql`
  query JoinUsQuery {
    strapiJoinUsPage {
      benefits_partner
      benefits_volunteer
      description
      title
    }
  }
`
